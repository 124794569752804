<template>
  <div>
    <v-card class="ma-3">
      <g-overlay :is-relay="isOverlay"/>
      <page-progress v-bind:is-loading="isLoading"/>
      <page-title icon="mdi-currency-btc" title="Crypto Payment Checker" />
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="6">
            <v-card>
              <v-card-title>Check Your Invoice Which Payment Method is Cryptocurrency</v-card-title>
              <v-card-text>
                  <v-form ref="cryptoCheckerForm" v-model="valid" lazy-validation>
                    <v-text-field
                        v-model="invoiceNo"
                        label="Input Your Invoice No. i.e(123456)"
                        :rules="requiredFieldRule"
                        onclick="this.select()"
                        outlined
                        required
                        dense
                    ></v-text-field>
                    <v-btn
                        class="mr-4"
                        @click="getCryptoStatus"
                    >
                      submit
                    </v-btn>
                    <v-btn
                        @click="resetCryptoForm"
                    >
                      clear
                    </v-btn>
                  </v-form>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card>
              <v-card-title>Invoice Status</v-card-title>
              <template v-if="alfaCoins && invoice">
                <v-card-text>
                  <p><strong>Type:</strong> <span> {{alfaCoins.type}}</span></p>
                  <p><strong>Invoice No.:</strong> <span> {{invoice.invoiceNo}}</span></p>
                  <p><strong>Invoice Pay Status:</strong> <span>
                  <g-payment-status-chip v-if="invoice.isDeleted && invoice.payStatus === 900" text="Removed" color="indigo"/>
                  <g-payment-status-chip v-else-if="invoice.isDeleted" text="Deleted" color="blue"/>
                  <g-payment-status-chip v-else-if="invoice.payStatus === 100"/>
                  <g-payment-status-chip v-else-if="invoice.payStatus === 200" color="green" text="Paid"/>
                  <g-payment-status-chip v-else-if="invoice.payStatus === 300" color="lime" text="Pending"/>
                  <g-payment-status-chip v-else-if="invoice.payStatus === 400" color="green darken-4" text="Refund"/>
                  <g-payment-status-chip v-else-if="invoice.payStatus === 600" color="orange" text="Republish"/>
                </span>
                  </p>
                  <p><strong>Status:</strong> <span> <v-chip
                      color="green"
                      outlined
                  >{{alfaCoins.status}}
                </v-chip>
                </span>
                  </p>
                  <p><strong>Address:</strong> <span> {{alfaCoins.address}}</span></p>
                  <p><strong>Amount:</strong> <span> {{alfaCoins.amount}}</span></p>
                  <p><strong>Coin Amount:</strong> <span> {{alfaCoins.coin_amount}}</span></p>
                  <p><strong>Coin Received Amount:</strong> <span> {{alfaCoins.coin_received_amount}}</span></p>
                  <p><strong>Coin Requested Amount:</strong> <span> {{alfaCoins.coin_requested_amount}}</span></p>
                  <p><strong>Currency:</strong> <span> {{alfaCoins.currency}}</span></p>
                  <p><strong>Date:</strong> <span> {{alfaCoins.date}}</span></p>
                  <p><strong>Payment Amount:</strong> <span> {{alfaCoins.fiat_paid_amount}}</span></p>
                  <p><strong>Payment URL:</strong> <span> {{alfaCoins.iframe}}</span></p>
                  <p><strong>Memo:</strong> <span> {{alfaCoins.memo}}</span></p>
                  <p><strong>Rate:</strong> <span> {{alfaCoins.rate}}</span></p>
                  <p><strong>Request Amount:</strong> <span> {{alfaCoins.requested_amount}}</span></p>
                  <p><strong>Invoice URL:</strong> <span> {{alfaCoins.url}}</span></p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn
                      color="primary"
                      class="mb-2"
                      outlined
                      @click="updatePaymentStatus"
                      v-if="alfaCoins.status === 'completed' && invoice.payStatus !== 200"
                  >
                    <v-icon dark>mdi-content-save</v-icon>
                    Pay Now
                  </v-btn>
                </v-card-actions>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import GOverlay from "../components/GOverlay";
import PageProgress from "../components/PageProgress";
import PageTitle from "../components/PageTitle";
import Validation from "../components/mixins/Validation";
import Cryptocurrency from "../services/Cryptocurrency";
import GPaymentStatusChip from "../components/GPaymentStatusChip";
export default {
  name: "Cryptocurrency",
  mixins: [Validation],
  components: {
    GPaymentStatusChip,
    PageTitle,
    PageProgress,
    GOverlay
  },
  data() {
    return {
      valid: true,
      isOverlay: false,
      isLoading: false,
      invoiceNo: '',
      alfaCoins: null,
      invoice: null
    }
  },
  methods: {
    getCryptoStatus() {
      this.alfaCoins = null;
      this.invoice = null;
      this.isOverlay = true;
      if(!this.$refs.cryptoCheckerForm.validate()) return;
      Cryptocurrency
          .getCurrencyStatus({invoiceNo: this.invoiceNo})
          .then(({data}) => {
            this.alfaCoins = data.order;
            this.invoice = data.invoice;
          })
          .catch((errors)=> {
            this.$store.commit('SET_SNACKBAR', {
              type: 'error',
              text: errors.response.data.message
            })
          })
          .finally(() => {
            this.$refs.cryptoCheckerForm.reset();
            this.isOverlay = false;
          })
    },
    updatePaymentStatus() {
      if(this.alfaCoins.status !== 'completed') return;
      if(this.invoice.payStatus === 200) return;
      let invoiceNo = this.invoice.invoiceNo;
      this.alfaCoins = null;
      this.invoice = null;
      this.isOverlay = true;
      Cryptocurrency
          .updateInvoiceStatus({invoiceNo})
          .then(({data}) => {
            this.alfaCoins = data.order;
            this.invoice = data.invoice;
          })
          .catch((errors)=> {
            this.$store.commit('SET_SNACKBAR', {
              type: 'error',
              text: errors.response.data.message
            })
          })
          .finally(() => {
            this.$refs.cryptoCheckerForm.reset();
            this.isOverlay = false;
          })
    },

    resetCryptoForm() {
      this.alfaCoins = null;
      this.invoice = null;
      this.$refs.cryptoCheckerForm.reset();
    }
  }
}
</script>

<style scoped>

</style>